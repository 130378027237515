import "./style.css";

import { digits } from "./digits";
import { Draggable } from "./draggable";

const iframe = document.querySelector("iframe");
const widget = SC.Widget(iframe);

let isPlaying = false;
let playerIsReady = false;

const mainLogo: HTMLElement = document.querySelector("#ten")!;
const digitEls = [document.querySelector(".d1"), document.querySelector(".d2")];
const tracklistDetails = Array.from(
  document.querySelectorAll(
    "#tracklist details"
  ) as NodeListOf<HTMLDetailsElement>
);

const setPlaying = () => {
  isPlaying = true;
};
const setPaused = () => {
  isPlaying = false;
};
const togglePlaying = () => {
  if (!playerIsReady) return;
  if (isPlaying) {
    widget.pause();
  } else {
    widget.play();
  }
};
const setTime = ({ currentPosition }: { currentPosition: number }) => {
  if (Math.abs(currentTime - currentPosition) > 5) {
    currentTime = currentPosition;
  }
};
const setReady = () => {
  playerIsReady = true;
};

widget.bind(SC.Widget.Events.READY, setReady);
widget.bind(SC.Widget.Events.PLAY, setPlaying);
widget.bind(SC.Widget.Events.PAUSE, setPaused);
widget.bind(SC.Widget.Events.SEEK, setTime);
widget.bind(SC.Widget.Events.PLAY_PROGRESS, setTime);
mainLogo.addEventListener("click", togglePlaying);

function toggleShine(shineButton: HTMLElement) {
  if (document.body.classList.contains("shine")) {
    document.body.classList.remove("shine");
    shineButton.innerText = "Enable Shine";
  } else {
    document.body.classList.add("shine");
    shineButton.innerText = "Disable Shine";
  }
}

export type Vec2 = { x: number; y: number };

document.body.addEventListener("keydown", (e) => {
  if (e.key === " ") {
    togglePlaying();
  }
});

const duration = 6000039 / 10;
let currentTime = 0;
let currentRotation = 0;
let lastRotation = 0;
let lastTime: number | null = null;
let lastTrack: number | null = null;
// loop
function loop(time: number) {
  if (isPlaying && lastTime !== null) {
    currentTime += time - lastTime;
  }
  currentRotation = (360 * currentTime) / duration;
  if (currentRotation !== lastRotation) {
    document.body.style.setProperty("--angle", `${currentRotation % 360}deg`);
  }
  // update digits
  const track = Math.floor(currentTime / (duration - 200));
  digitEls[0]?.setAttribute("d", track === 0 ? digits[0][1] : digits[0][0]);
  digitEls[1]?.setAttribute("d", digits[1][(10 - track) % 10]);

  if (lastTrack !== track) {
    // update tracklist
    tracklistDetails.forEach((dt, i) => {
      dt.open = i === track;
      if (i === track) {
        dt.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        dt.classList.add("np");
      } else {
        dt.classList.remove("np");
      }
    });
  }

  // store last values
  lastTime = time;
  lastRotation = currentRotation;
  lastTrack = track;

  requestAnimationFrame(loop);
}

requestAnimationFrame(loop);

(async () => {
  new Draggable("introButton", "intro").init().show(
    window.innerWidth > 800
      ? {
          x: Math.random() * window.innerWidth,
          y: Math.random() * window.innerHeight,
        }
      : undefined
  );
  new Draggable("tracklistButton", "tracklist").init();
  let code = "";
  try {
    const res = await fetch("/api/code");
    code = await res.text();
  } catch (err) {
    code = "Sorry, please allow cookies to get codes!";
  } finally {
    const shineButton = document.querySelector(
      "#shine button.toggle"
    ) as HTMLElement;
    new Draggable("shineButton", "shine", {
      setup: (el) => {
        el.querySelector(".toggle")?.addEventListener("click", () => {
          toggleShine(shineButton);
        });
      },
      drop: { format: "text/plain", data: code },
    }).init();
  }
})();

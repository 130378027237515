import { Vec2 } from ".";

let dialogZ = 1000;

export class Draggable {
  static instances: Draggable[] = [];
  triggerEl: HTMLElement;
  dialogEl: HTMLDialogElement;
  isDragging = false;
  options?: {
    setup?: (el: HTMLDialogElement) => void;
    drop?: { format: string; data: string };
  };
  initialPos: Vec2 = { x: 0, y: 0 };
  targetPos: Vec2 | null = null;

  constructor(
    triggerId: string,
    dialogId: string,
    options?: {
      setup?: (el: HTMLDialogElement) => void;
      drop?: { format: string; data: string };
    }
  ) {
    const triggerEl = document.getElementById(triggerId);
    const dialogEl = document.getElementById(dialogId) as HTMLDialogElement;
    if (triggerEl === null) throw new Error("Trigger element not found");
    if (dialogEl === null) throw new Error("Dialog element not found");
    this.triggerEl = triggerEl;
    this.dialogEl = dialogEl;
    this.options = options;
    return this;
  }
  init() {
    if (this.options?.setup) {
      this.options.setup(this.dialogEl);
    }
    // Dialog showing
    this.triggerEl.addEventListener("click", (event) => {
      this.show(event);
    });

    // Dragging
    this.dialogEl.addEventListener("dragstart", async (e) => {
      this.initialPos = { x: e.offsetX, y: e.offsetY };
      this.isDragging = true;

      e.dataTransfer!.setData(
        this.options?.drop?.format || "text/plain",
        this.options?.drop?.data || ""
      );
      e.dataTransfer!.dropEffect = "move";
      e.dataTransfer!.effectAllowed = "move";

      this.dialogEl.style.zIndex = `${++dialogZ}`;
    });
    this.dialogEl.addEventListener("dragend", (e) => {
      this.isDragging = false;
      if (this.targetPos !== null) {
        this.dialogEl.style.left = `${this.targetPos.x - this.initialPos.x}px`;
        this.dialogEl.style.top = `${this.targetPos.y - this.initialPos.y}px`;
        this.dialogEl.style.right = "";
        this.dialogEl.style.bottom = "";
      }
    });
    Draggable.instances.push(this);
    return this;
  }
  show(position?: { x: number; y: number }) {
    this.dialogEl.style.zIndex = `${++dialogZ}`;
    if (position) {
      if (position.x > window.innerWidth / 2) {
        this.dialogEl.style.right = `${window.innerWidth - position.x}px`;
        this.dialogEl.style.left = "";
      } else {
        this.dialogEl.style.left = `${position.x}px`;
        this.dialogEl.style.right = "";
      }
      if (position.y > window.innerHeight / 2) {
        this.dialogEl.style.bottom = `${window.innerHeight - position.y}px`;
        this.dialogEl.style.top = "";
      } else {
        this.dialogEl.style.top = `${position.y}px`;
        this.dialogEl.style.bottom = "";
      }
    }
    this.dialogEl.show();
  }

  static handleDragOver(e: DragEvent) {
    const currentlyDragging = Draggable.instances.find((d) => d.isDragging);
    if (currentlyDragging) {
      if (e.clientX !== 0 && e.clientY !== 0) {
        currentlyDragging.targetPos = { x: e.clientX, y: e.clientY };
      } else {
        currentlyDragging.targetPos = null;
      }
    }
    e.preventDefault();
  }
  static preventDefault(e: DragEvent) {
    e.preventDefault();
  }
}

document.body.addEventListener("dragenter", Draggable.preventDefault);
document.body.addEventListener("dragover", Draggable.handleDragOver);
document.body.addEventListener("drop", Draggable.preventDefault);

export const digits = [
  [
    "m59.293 296.14c-24.985 0-41.521-64.158-41.521-147.327 0-83.961 17.27-147.328 41.521-147.328 24.434 0 41.52 62.575 41.52 147.328s-17.086 147.327-41.52 147.327zm-23.884-140.198c0 26.534.735 64.158 5.145 92.674 3.307 21.386 8.818 35.644 18.556 35.644 16.351 0 24.066-41.98 24.066-135.446 0-92.278-6.798-135.446-23.883-135.446-7.165 0-12.31 8.317-15.8 22.178-5.512 21.782-6.431 40.792-7.899 85.545-.185 5.941-.185 22.574-.185 34.852z",
    "m68.662 251.387c0 15.842 1.103 26.14 3.123 30.496 2.021 4.356 4.042 5.94 13.595 5.94v5.94h-48.133v-5.94c9.186 0 11.39-2.376 13.411-6.733 2.021-4.356 3.123-13.069 3.123-29.703v-197.625c0-12.277-2.572-19.802-7.716-19.802-2.205 0-5.695 2.376-9.187 6.733l-1.102-3.961 15.432-17.822c10.656-11.881 15.617-17.821 16.352-17.821.919 0 1.103 1.188 1.103 4.753v245.545z",
  ],
  [
    "m151.152 296.14c-24.985 0-41.521-64.158-41.521-147.327.001-83.961 17.27-147.329 41.521-147.329 24.434 0 41.52 62.575 41.52 147.328s-17.086 147.327-41.52 147.327zm-23.884-140.198c0 26.534.735 64.158 5.145 92.674 3.307 21.386 8.818 35.644 18.556 35.644 16.351 0 24.066-41.98 24.066-135.446 0-92.277-6.798-135.446-23.883-135.446-7.165 0-12.31 8.317-15.8 22.179-5.512 21.781-6.431 40.792-7.899 85.545-.185 5.94-.185 22.574-.185 34.852z",
    "m160.521 251.387c0 15.842 1.103 26.14 3.123 30.496 2.021 4.356 4.042 5.94 13.595 5.94v5.94h-48.133v-5.94c9.186 0 11.39-2.376 13.411-6.733 2.021-4.356 3.123-13.069 3.123-29.703v-197.625c0-12.277-2.572-19.802-7.716-19.802-2.205 0-5.695 2.376-9.187 6.733l-1.102-3.96 15.432-17.822c10.655-11.881 15.616-17.822 16.351-17.822.919 0 1.103 1.188 1.103 4.752v245.545z",
    "m171.36 155.545c-7.899 25.743-22.597 64.555-44.459 112.476 11.391 0 22.781.396 34.171.396 13.228 0 22.781-3.96 27.925-25.347l2.021.792-8.268 49.901h-73.67c4.593-11.881 12.125-29.307 21.495-51.485l13.778-32.476c6.43-15.05 13.595-35.644 18.556-55.05 3.857-15.446 6.062-32.872 6.062-53.069 0-20.99-2.389-38.812-7.165-52.674s-11.574-20.594-18.556-20.594c-13.044 0-22.046 15.842-27.373 47.921h-2.572c5.145-45.94 18.556-73.663 36.009-73.663 9.187 0 17.82 7.921 24.251 23.367 6.43 15.445 9.553 34.852 9.553 57.426 0 24.951-3.857 46.337-11.758 72.08z",
    "m161.072 122.277c15.616 12.674 24.067 38.416 24.067 74.853 0 25.347-4.776 50.297-14.514 70.099-9.737 19.803-22.413 29.703-35.09 29.703-12.493 0-20.393-11.089-20.393-27.326 0-9.505 2.572-15.446 6.798-15.446 3.857 0 6.613 4.356 11.022 12.277 4.226 7.921 9.187 12.277 14.881 12.277 13.411 0 22.965-26.139 22.965-62.179 0-19.01-2.756-38.02-8.451-50.297-6.43-14.258-13.962-19.01-21.679-19.01-1.469 0-2.755.396-4.225.792l-.552-5.149c17.453-8.316 27.925-32.078 27.925-63.762 0-31.288-8.818-52.674-22.046-52.674-10.288 0-16.351 10.693-21.311 34.455h-2.572c3.858-38.02 15.248-59.406 31.415-59.406 7.165 0 15.065 5.544 20.209 17.426 5.145 11.485 7.9 25.743 7.9 43.168 0 20.99-3.858 36.04-16.352 60.198z",
    "m166.584 3.465h8.451v185.744h18.371v25.742h-18.371v78.812h-14.514v-78.812h-51.44v-25.742zm-6.062 41.98-44.643 143.764h44.643z",
    "m188.263 3.465-6.062 28.516h-38.213l-10.288 48.713c32.885 13.861 50.889 55.842 50.889 112.08 0 27.326-4.776 53.069-14.514 73.663-9.736 20.198-21.678 30.495-34.722 30.495-11.758 0-20.025-11.484-20.025-26.931 0-7.524 2.572-13.069 6.062-13.069s6.431 3.961 11.574 11.089c4.961 6.733 9.37 10.693 13.411 10.693 6.981 0 14.146-6.732 19.475-20.198 5.327-13.861 7.899-29.703 7.899-48.316 0-24.951-4.96-47.129-15.064-66.535-9.921-19.01-23.148-30.1-37.479-31.684l22.781-108.516h44.275z",
    "m190.467 6.237c-26.639 5.94-48.133 46.337-56.4 122.772 6.798-9.108 14.514-13.861 22.597-13.861 9.187 0 18.556 7.525 24.802 23.763 6.062 15.842 9.186 36.832 9.186 62.179 0 28.119-4.225 51.485-11.022 68.516-6.798 17.029-16.351 26.931-26.639 26.931-10.839 0-20.576-10.297-27.741-31.684-6.981-20.99-10.472-48.317-10.472-82.377 0-47.921 7.349-94.258 22.046-129.505 14.696-35.248 33.25-51.487 53.643-51.487zm-15.799 209.902c0-53.466-9.002-84.753-24.985-84.753-12.493 0-19.475 13.861-19.475 60.198 0 59.01 9.003 93.466 24.435 93.466 12.677 0 20.025-24.555 20.025-68.911z",
    "m188.63 3.465-39.132 293.071-9.002-5.148 34.723-259.804h-27.925c-12.677 0-19.106 3.168-22.965 9.505-3.857 5.94-6.98 16.237-9.186 30.892h-2.756l11.024-68.516z",
    "m159.603 136.139c4.226 8.713 8.268 16.238 12.86 26.535 6.614 15.05 13.595 35.248 13.595 63.366 0 41.188-13.778 70.1-35.273 70.1-19.841 0-34.171-26.534-34.171-66.139 0-29.703 7.716-53.069 26.271-80.001-19.842-37.623-24.066-54.258-24.066-81.584 0-38.812 12.859-66.139 33.068-66.139 18.556 0 31.6 25.346 31.6 62.178 0 26.535-6.062 43.169-23.884 71.684zm14.146 102.575c0-16.237-4.409-34.06-13.044-52.674-8.635-18.613-13.228-26.931-13.962-29.703-11.942 19.01-17.821 41.98-17.821 70.496 0 34.852 9.003 57.821 22.965 57.821 13.228 0 21.862-19.01 21.862-45.94zm-1.653-174.259c0-31.684-7.532-50.693-20.943-50.693-11.574 0-19.658 18.614-19.658 44.357 0 24.158 7.349 43.564 24.251 70.1 10.839-18.615 16.351-40.001 16.351-63.764z",
    "m166.217 244.259c-14.146 34.06-31.231 52.277-53.646 52.277v-5.148c28.477-6.337 47.95-51.09 56.218-122.773-6.798 9.505-14.33 14.258-22.414 14.258-9.92 0-18.738-8.317-24.801-23.763-6.247-16.238-9.187-37.624-9.187-62.179 0-25.743 3.491-49.901 10.655-68.119 7.165-18.614 16.902-27.723 26.823-27.723 11.022 0 20.576 10.692 27.558 30.892 7.164 20.99 10.655 49.9 10.655 83.168 0 45.545-6.798 92.674-21.862 129.11zm6.613-138.219c0-58.218-9.186-93.466-24.801-93.466-11.574 0-19.842 21.782-19.842 69.307 0 52.674 9.369 84.357 24.802 84.357 14.146 0 19.841-19.01 19.841-60.198z",
  ],
];
